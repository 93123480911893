import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FundsState } from '@fund-base/store/state/funds.state';
import { FundsOpenSearch } from '@fund-base/types/funds/funds.types';
import { featureFundsKey } from '@fund-base/store/reducers/funds.reducer';
import { FundingExpert } from '@fund-base/types/funding-expert/expert.types';
import { FiltersSearch, FundsFilter, SearchHistory, SearchQuery } from '@fund-base/types/funds/funds-search.types';

// store
const getFundsLoading = (state: FundsState): boolean => state.loading;
const getFundsFilterCountLoading = (state: FundsState): boolean => state.filtersCountLoading;

const getFunds = (state: FundsState): FundsOpenSearch[] | undefined => state.fundsList;
const getFundsCount = (state: FundsState): number | undefined => state.fundsCount;
const getFundsFilters = (state: FundsState): FundsFilter[] | undefined => state.filtersCount;

const getFundsSearchQuery = (state: FundsState): SearchQuery | undefined => state.searchQuery;
const getFundsAdvancedFilters = (state: FundsState): FiltersSearch | undefined => state.filtersSearch;
const getFundingExperts = (state: FundsState): FundingExpert[] | undefined => state.fundingExperts?.fundingExpertsList;
const getFundingExpertsCount = (state: FundsState): number | undefined => state.fundingExperts?.totalCount;

const getUserHistory = (state: FundsState): SearchHistory[] | undefined => state.usersSearchHistory;

const getFundsPage = (state: FundsState): number | undefined => state.page;
const getFundsSize = (state: FundsState): number | undefined => state.size;

// settings state
export const selectFundsState: MemoizedSelector<object, FundsState> =
  createFeatureSelector<FundsState>(featureFundsKey);

// loading
export const selectFundsLoading: MemoizedSelector<object, boolean> = createSelector(selectFundsState, getFundsLoading);

// loading
export const selectFundsFilterCountLoading: MemoizedSelector<object, boolean> = createSelector(
  selectFundsState,
  getFundsFilterCountLoading
);

// funds
export const selectFunds: MemoizedSelector<object, FundsOpenSearch[] | undefined> = createSelector(
  selectFundsState,
  getFunds
);

export const selectFundsCount: MemoizedSelector<object, number | undefined> = createSelector(
  selectFundsState,
  getFundsCount
);

// filters
export const selectFundsFilters: MemoizedSelector<object, FundsFilter[] | undefined> = createSelector(
  selectFundsState,
  getFundsFilters
);
export const selectFiltersSearch: MemoizedSelector<object, FiltersSearch | undefined> = createSelector(
  selectFundsState,
  getFundsAdvancedFilters
);
export const selectFundsSearchQuery: MemoizedSelector<object, SearchQuery | undefined> = createSelector(
  selectFundsState,
  getFundsSearchQuery
);

// funding experts
export const selectFundingExperts: MemoizedSelector<object, FundingExpert[] | undefined> = createSelector(
  selectFundsState,
  getFundingExperts
);

// funding experts count
export const selectFundingExpertsCount: MemoizedSelector<object, number | undefined> = createSelector(
  selectFundsState,
  getFundingExpertsCount
);

// user history
export const selectUserHistory: MemoizedSelector<object, SearchHistory[] | undefined> = createSelector(
  selectFundsState,
  getUserHistory
);

export const selectFundsPage: MemoizedSelector<object, number | undefined> = createSelector(
  selectFundsState,
  getFundsPage
);

export const selectFundsSize: MemoizedSelector<object, number | undefined> = createSelector(
  selectFundsState,
  getFundsSize
);
