import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, switchMap, tap, throwError, withLatestFrom } from 'rxjs';
import {
  authenticate,
  authResetPassword,
  authWithGoogle,
  authWithGoogleCalendar,
  fetchNumbersFreePlanVisitors,
  forgotPassword,
  getGoogleAuthInfo,
  getGoogleAuthInfoCalendar,
  login,
  loginWithGoogle,
  setAuthLoading,
  setToken,
  setUser,
  setVisitor,
  signOut,
  signUp,
  signUpIsEmailVerified,
  signUpIsVisitorEmailVerified,
  signUpMembership,
  signUpOldFbAccount,
  signUpOrganization,
  signUpReSendEmailVerification,
  signUpSuccess,
  signUpVisitor,
  signUpVisitorPlan,
  signUpVisitorUpdate,
  transformVisitorToUser,
  updateNumberAiLettersVisitors,
} from '../actions/auth.actions';
import { selectToken } from '@fund-base/store/selectors/auth.selectors';
import { authServiceInjectionToken, authTokenLocalStorageKey } from '@fund-base/constants/auth/auth.constants';
import { AuthService } from '@fund-base/services/auth/auth.service';
import { StorageService } from '@fund-base/services/storage/storage.service';
import { ErrorService } from '@fund-base/services/error/error.service';
import { setGlobalLoading, showToast } from '@fund-base/store/actions/ui.actions';
import { ToastType } from '@fund-base/types/ui/ui.types';
import { TranslocoService } from '@ngneat/transloco';
import { OAuthService } from 'angular-oauth2-oidc';
import { GoogleService } from '@fund-base/services/google/google.service';
import { Router } from '@angular/router';
import { setOrganization } from '@fund-base/store/actions/organization.actions';
import { clearState } from '@fund-base/store/actions/meta.actions';

@Injectable()
export class AuthEffects {
  // authenticate
  authenticate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authenticate),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.authenticate().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // login
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(login),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.login(action?.email, action?.password).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) {
              action?.onSuccess(response);
            }
            // on success
            return [
              setToken({ token: response?.accessToken }),
              !!response?.user?.organization
                ? setUser({ user: response?.user })
                : setVisitor({ visitor: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) {
              action?.onError(error);
            }

            // on error
            return [
              setAuthLoading({ loading: false }),
              // showToast({content: error, status: ToastType.failure})
            ];
          })
        );
      })
    )
  );

  // signup details
  signUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUp),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.signUp(action?.user).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // signup details
  signUpVisitor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpVisitor),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.signUpVisitor(action?.user).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [
              setToken({ token: response?.accessToken }),
              setVisitor({ visitor: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              // showToast({content: error, status: ToastType.failure})
            ];
          })
        );
      })
    )
  );

  // signup visitor update details
  signUpVisitorPlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpVisitorPlan),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.signUpVisitorPlan(action?.plan).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [setAuthLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // signup visitor update details
  signUpVisitorUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpVisitorUpdate),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.signUpVisitorUpdate(action?.user).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);
            // on success
            return [setAuthLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  signUpOldFbAccount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpOldFbAccount),
      switchMap(action => {
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.authService
          .signUpOldFbAccount(action?.email, action?.userName, action?.oldPassword, action?.newPassword)
          .pipe(
            switchMap(response => {
              // on success callback
              if (!!action?.onSuccess) action?.onSuccess(response);
              // on success
              return [setAuthLoading({ loading: false })];
            }),
            catchError(errorRes => {
              // get error
              const error = this.errorService.getErrorMessage(errorRes);

              // on error callback
              if (!!action?.onError) action?.onError?.call(error);

              // on error
              return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
            })
          );
      })
    )
  );

  // auth google
  getGoogleAuthInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGoogleAuthInfo),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.googleService.getGoogleToken().pipe(
          switchMap(googleTokenResponse => {
            if (!googleTokenResponse?.access_token) {
              return throwError(() => googleTokenResponse?.error);
            }

            // got access token
            return this.googleService.getGoogleTokenInfo(googleTokenResponse?.access_token).pipe(
              switchMap(googleTokenInfoResponse => {
                if (!googleTokenInfoResponse?.email) {
                  return throwError(() => googleTokenInfoResponse?.error);
                }

                const response = {
                  accessToken: googleTokenResponse?.access_token,
                  email: googleTokenInfoResponse?.email,
                };

                // on success callback
                if (!!action?.onSuccess) action?.onSuccess(response);

                // on success
                return [setAuthLoading({ loading: false })];
              }),
              catchError(errorRes => {
                return throwError(() => errorRes);
              })
            );
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              // showToast({content: error, status: ToastType.failure})
            ];
          })
        );
      })
    )
  );

  // auth google
  authWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authWithGoogle),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.googleService.authWithGoogle(action?.email, action?.accessToken).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);
            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // login by google
  loginWithGoogle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginWithGoogle),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.googleService.loginWithGoogle(action?.email, action?.accessToken).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);
            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              // showToast({content: error, status: ToastType.failure})
            ];
          })
        );
      })
    )
  );

  // signup organization
  signUpOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpOrganization),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service

        return this.authService.signUpOrganization(action?.organization).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // signup re-send-email-verification
  signUpReSendEmailVerification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpReSendEmailVerification),
      withLatestFrom(this.store.select(selectToken)),

      switchMap(([action, tokenFromStore]) => {
        const token = action?.token || tokenFromStore || this?.storageService?.getItem(authTokenLocalStorageKey);
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.authService.signUpReSendEmailVerification(token).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setToken({ token: response?.accessToken }),
              setAuthLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Email has been sent Successfully'),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // signup is email verified
  signUpIsEmailVerified$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpIsEmailVerified),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.authService.signUpIsEmailVerified().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setUser({ user: response?.user }),
              setToken({ token: response?.accessToken }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Pleas verify your email'),
                status: ToastType.failure,
              }),
            ];
          })
        );
      })
    )
  );
  transformVisitorToUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(transformVisitorToUser),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.authService.transformVisitorToUser().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setUser({ user: response?.user }),
              setToken({ token: response?.accessToken }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate('Failed to Add User'),
                status: ToastType.failure,
              }),
            ];
          })
        );
      })
    )
  );
  signUpIsVisitorEmailVerified = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpIsVisitorEmailVerified),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.authService.signUpIsVisitorEmailVerified().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setVisitor({ visitor: response?.user }),
              setToken({ token: response?.accessToken }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false })];
          })
        );
      })
    )
  );

  // signup membership
  signUpMembership$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpMembership),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service

        return this.authService.signUpMembership(action?.plan_id).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // signup Success
  signUpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(signUpSuccess),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service

        return this.authService.authenticate().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess?.call(response);

            // on success
            return [
              setToken({ token: response?.accessToken }),
              setUser({ user: response?.user }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  setToken$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setToken),
        tap(action => {
          this.storageService.setItem(authTokenLocalStorageKey, action?.token);
        })
      ),
    { dispatch: false }
  );

  setUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setUser),
      switchMap(action => {
        // this.storageService.setItem(authUserLocalStorageKey, action?.user);
        return [setOrganization({ organization: action?.user?.organization })];
      })
    )
  );

  signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(signOut),
        tap(() => {
          this.store.dispatch(clearState({}));
          this.storageService.clearStorage();
          this.router.navigate(['auth']);
        })
      ),
    { dispatch: false }
  );

  // auth google
  getGoogleAuthInfoCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getGoogleAuthInfoCalendar),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));
        return this.googleService.getGoogleTokenCalendar().pipe(
          switchMap(googleTokenResponse => {
            if (!googleTokenResponse?.access_token) {
              return throwError(() => googleTokenResponse?.error);
            }

            // got access token
            return this.googleService.getGoogleTokenInfo(googleTokenResponse?.access_token).pipe(
              switchMap(googleTokenInfoResponse => {
                if (!googleTokenInfoResponse?.email) {
                  return throwError(() => googleTokenInfoResponse?.error);
                }

                const response = {
                  accessToken: googleTokenResponse?.access_token,
                  email: googleTokenInfoResponse?.email,
                };

                // on success callback
                if (!!action?.onSuccess) action?.onSuccess(response);

                // on success
                return [
                  setAuthLoading({ loading: false }),
                  showToast({
                    content: this.translocoService.translate('Got user google auth info'),
                    status: ToastType.success,
                  }),
                ];
              }),
              catchError(errorRes => {
                return throwError(() => errorRes);
              })
            );
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // auth google
  authWithGoogleCalendar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authWithGoogleCalendar),
      switchMap(action => {
        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.googleService.googleCalendar(action?.email, action?.accessToken).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              setAuthLoading({ loading: false }),
              showToast({
                content: this.translocoService.translate(
                  'The events have been successfully added to the Google calendar'
                ),
                status: ToastType.success,
              }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError(error);

            // on error
            return [
              setAuthLoading({ loading: false }),
              // showToast({content: error, status: ToastType.failure})
            ];
          })
        );
      })
    )
  );

  // authenticate
  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(forgotPassword),
      switchMap(action => {
        // get token

        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.forgotPassword(action?.forgotPasswordDto).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [
              showToast({
                content: this.translocoService.translate('Forgot Password Mail Sent'),
                status: ToastType.success,
              }),
              setAuthLoading({ loading: false }),
            ];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // reset password
  resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authResetPassword),
      switchMap(action => {
        // get token

        // set auth loading
        this.store.dispatch(setAuthLoading({ loading: true }));

        // call authenticate from service
        return this.authService.resetPassword(action?.resetPasswordDto).pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [setAuthLoading({ loading: false })];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [setAuthLoading({ loading: false }), showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // fetch Number ai letters visitors
  fetchNumbersFreePlanVisitors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchNumbersFreePlanVisitors),
      switchMap(action => {
        // set Funds loading
        return this.authService.fetchNumbersFreePlanVisitors().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  // update Number ai letters visitors
  updateNumberAiLettersVisitors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateNumberAiLettersVisitors),
      switchMap(action => {
        // set Funds loading
        return this.authService.updateNumberAiLettersVisitors().pipe(
          switchMap(response => {
            // on success callback
            if (!!action?.onSuccess) action?.onSuccess(response);

            // on success
            return [];
          }),
          catchError(errorRes => {
            // get error
            const error = this.errorService.getErrorMessage(errorRes);

            // on error callback
            if (!!action?.onError) action?.onError?.call(error);

            // on error
            return [showToast({ content: error, status: ToastType.failure })];
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private router: Router,
    private storageService: StorageService,
    private errorService: ErrorService,
    private translocoService: TranslocoService,
    private oAuthService: OAuthService,
    private googleService: GoogleService,
    @Inject(authServiceInjectionToken) private authService: AuthService
  ) {}
}
